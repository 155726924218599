import {
  FeatureServiceBinder,
  FeatureServiceProviderDefinition,
  FeatureServices,
  SharedFeatureService,
} from '@feature-hub/core';

import {
  InPageNavigationServiceV1,
  InPageNavigationServiceV1Imp,
  InPageNavigationServiceV1Config,
} from './v1';

export * from './v1/types';

interface SharedInPageNavigationService extends SharedFeatureService {
  '0.0.1': FeatureServiceBinder<InPageNavigationServiceV1>;
}

interface RequiredFeatureServices extends FeatureServices {}

export function defineInPageNavigationService(
  inputConfig: InPageNavigationServiceV1Config
): FeatureServiceProviderDefinition<
  SharedInPageNavigationService,
  RequiredFeatureServices
> {
  return {
    id: 'audi-in-page-navigation-service',

    optionalDependencies: {
      featureServices: {},
    },

    create: () => {
      const inPageNavigationServiceProvider = new InPageNavigationServiceV1Imp(
        inputConfig
      );

      return {
        '0.0.1': () => ({ featureService: inPageNavigationServiceProvider }),
      };
    },
  };
}
