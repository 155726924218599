/**
 * @deprecated This Feature App moved! New versions will be published as [@oneaudi/fa-anchor-navigation](https://github.com/oneaudi/fa-anchor-navigation). Update all of your dependencies now!
 */
export const useDebounce = (
  callback: (args: Event) => void,
  time: number
): EventListenerOrEventListenerObject => {
  let interval: NodeJS.Timeout | null;
  return (...args: [evt: Event]) => {
    if (interval) clearTimeout(interval);
    interval = setTimeout(() => {
      interval = null;
      callback(...args);
    }, time);
  };
};
