import { useContext, useMemo } from 'react';
import { FeatureAppContext } from '../context/FeatureAppContext';

/**
 * @deprecated This Feature App moved! New versions will be published as [@oneaudi/fa-anchor-navigation](https://github.com/oneaudi/fa-anchor-navigation). Update all of your dependencies now!
 */
export interface TrackingManager {
  ready(version: string): void;
  impression(): void;
  click(label: string, anchorIndex: number): void;
  navigation(isOpen: boolean): void;
}

/**
 * @deprecated This Feature App moved! New versions will be published as [@oneaudi/fa-anchor-navigation](https://github.com/oneaudi/fa-anchor-navigation). Update all of your dependencies now!
 */
export interface TrackingEvent {
  event: {
    eventInfo: {
      eventAction: string;
      eventName: string;
    };
    attributes: Record<string, unknown>;
  };
}

function createTrackingPayload(
  action: string,
  name: string,
  attributes: Record<string, unknown>
): TrackingEvent {
  const { host, pathname } = document.location;
  const defaultAttributes = {
    componentName: 'anchor-navigation',
    currentURL: `${host}${pathname}`,
  };

  return {
    event: {
      eventInfo: {
        eventAction: action,
        eventName: name,
      },
      attributes: {
        ...defaultAttributes,
        ...attributes,
      },
    },
  };
}
function createComponentPayload(version: string) {
  return {
    componentInfo: {
      implementer: 2,
      version,
    },
  };
}

/**
 * @deprecated This Feature App moved! New versions will be published as [@oneaudi/fa-anchor-navigation](https://github.com/oneaudi/fa-anchor-navigation). Update all of your dependencies now!
 */
export function useTrackingManager(): TrackingManager {
  const { trackingService, logger } = useContext(FeatureAppContext);

  return useMemo((): TrackingManager => {
    function track(...args: Parameters<typeof createTrackingPayload>): void {
      if (trackingService) {
        const payload = createTrackingPayload(...args);
        logger?.debug('Sending tracking event', payload);
        trackingService.track(payload);
      }
    }

    function trackWithComponent(
      version: string,
      ...args: Parameters<typeof createTrackingPayload>
    ): void {
      if (trackingService) {
        const payload = createTrackingPayload(...args);

        logger && logger.debug('Sending tracking event: ', payload);

        const componentPayload = createComponentPayload(version);
        logger && logger.debug('Sending component info', componentPayload);

        trackingService.track({ ...payload, componentUpdate: componentPayload });
      }
    }

    return {
      ready(version) {
        trackWithComponent(version, 'feature_app_ready', 'anchor navigation - feature app ready', {
          label: '',
          targetURL: '',
          clickID: '',
          elementName: '',
          value: '',
          pos: '',
        });
      },
      impression() {
        track('impression', 'anchor navigation - impression', {
          label: '',
          targetURL: '',
          clickID: '',
          elementName: '',
          value: '',
          pos: '',
        });
      },
      click(label, anchorIndex) {
        track('content', 'anchor navigation - click on anchor link', {
          label,
          targetURL: '',
          clickID: '',
          elementName: 'text link',
          value: '',
          pos: anchorIndex,
        });
      },

      navigation(isOpen) {
        const action = isOpen ? 'close' : 'open';
        const label = isOpen ? 'Navigation schließen' : 'Navigation öffnen';
        track('navigation', `anchor navigation - ${action} navigation`, {
          label,
          targetURL: '',
          clickID: '',
          elementName: 'text link',
          value: '',
          pos: '',
        });
      },
    };
  }, [trackingService]);
}
