import { TokenizedTheme } from '@audi/audi-ui-react';
import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle<{ theme: TokenizedTheme }>`
html {
  // height of anchor nav to avoid overlapping content
    scroll-padding-top:  56px;
  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    scroll-padding-top:  72px;
    
  }
}`;

/**
 * @deprecated This Feature App moved! New versions will be published as [@oneaudi/fa-anchor-navigation](https://github.com/oneaudi/fa-anchor-navigation). Update all of your dependencies now!
 */
export default GlobalStyles;
