import { getFullDomHeight } from './get-full-dom-height';

/**
 * @deprecated This Feature App moved! New versions will be published as [@oneaudi/fa-anchor-navigation](https://github.com/oneaudi/fa-anchor-navigation). Update all of your dependencies now!
 */
export interface AnchorScrollData {
  start: number;
  end: number;
}

/**
 * @deprecated This Feature App moved! New versions will be published as [@oneaudi/fa-anchor-navigation](https://github.com/oneaudi/fa-anchor-navigation). Update all of your dependencies now!
 */
export const getAnchorOffsets = (elements: HTMLElement[]): AnchorScrollData[] => {
  const offsets: number[] = [];
  const offsetsData: AnchorScrollData[] = [];
  elements.forEach((el: HTMLElement) => {
    /* due to weird rounding errors in some browsers we need to add a tolerance of 1px, 
    please don't remove unless you know what you're doing */
    offsets.push(el.offsetTop - 1);
  });
  offsets.forEach((offset: number, index: number) => {
    offsetsData.push({
      start: offset,
      end: index === offsets.length - 1 ? getFullDomHeight() : offsets[index + 1],
    });
  });
  return offsetsData;
};
