import * as React from 'react';
import styled from 'styled-components';

const StyledIcon = styled.svg`
  grid-column: arrow-icon;
  width: 1.5em;
  height: 1.5em;
  color: ${(props) => props.theme.colors.ui.primary};
  transform: rotateZ(0deg);
  transition: transform 200ms ${(props) => props.theme.easing};
  pointer-events: none;
  flex-shrink: 0;

  &.rotated {
    transform: rotateZ(-180deg);
  }
`;

/**
 * @deprecated This Feature App moved! New versions will be published as [@oneaudi/fa-anchor-navigation](https://github.com/oneaudi/fa-anchor-navigation). Update all of your dependencies now!
 */
export interface ArrowIconProps {
  readonly rotated: boolean;
}

/**
 * @deprecated This Feature App moved! New versions will be published as [@oneaudi/fa-anchor-navigation](https://github.com/oneaudi/fa-anchor-navigation). Update all of your dependencies now!
 */
export const ArrowIcon: React.FC<ArrowIconProps> = ({ rotated }) => (
  <StyledIcon
    className={rotated ? 'rotated' : ''}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 10L12.5 15.5 18 10"
      stroke="currentColor"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    />
  </StyledIcon>
);
