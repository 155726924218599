import React from 'react';

import { I18nTexts } from './index';

interface State {
  readonly i18nMessages: I18nTexts;
}

interface ContextValue {
  readonly children: JSX.Element;
  readonly i18nMessages: I18nTexts;
}

/**
 * @deprecated This Feature App moved! New versions will be published as [@oneaudi/fa-anchor-navigation](https://github.com/oneaudi/fa-anchor-navigation). Update all of your dependencies now!
 */
export const I18nContext = React.createContext<State>({} as State);

/**
 * @deprecated This Feature App moved! New versions will be published as [@oneaudi/fa-anchor-navigation](https://github.com/oneaudi/fa-anchor-navigation). Update all of your dependencies now!
 */
export const I18nContextProvider = (props: ContextValue): JSX.Element => {
  const { children, i18nMessages } = props;

  const state: State = {
    i18nMessages,
  };

  return <I18nContext.Provider value={state}>{children}</I18nContext.Provider>;
};
