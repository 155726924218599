/**
 * @deprecated This Feature App moved! New versions will be published as [@oneaudi/fa-anchor-navigation](https://github.com/oneaudi/fa-anchor-navigation). Update all of your dependencies now!
 */
export const getFullDomHeight = (): number => {
  const { body } = document;
  const html = document.documentElement;
  return Math.max(
    body.scrollHeight,
    body.offsetHeight,
    html.clientHeight,
    html.scrollHeight,
    html.offsetHeight
  );
};
