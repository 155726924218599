/**
 * @deprecated This Feature App moved! New versions will be published as [@oneaudi/fa-anchor-navigation](https://github.com/oneaudi/fa-anchor-navigation). Update all of your dependencies now!
 */
export type Content = {
  headline: string;
  lightDarkMode: THEME.LIGHT | THEME.DARK;
};

/**
 * @deprecated This Feature App moved! New versions will be published as [@oneaudi/fa-anchor-navigation](https://github.com/oneaudi/fa-anchor-navigation). Update all of your dependencies now!
 */
export enum THEME {
  LIGHT = 'light',
  DARK = 'dark',
}
